import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './index.css'

import Frontpage from './containers/Frontpage';
import WishList from './containers/WishList';
import Albums from './containers/Albums';
import Members from './containers/Members';

import NavigationBar from './components/NavigationBar';
import Authenticator from './components/Authenticator';

export default function App() {
  return (
    <Router>
      <div>
        <NavigationBar />
        <Switch>
          <Route path="/wish-list">
            <Authenticator>
              <WishList />
            </Authenticator> 
          </Route>
          <Route path="/albums">
            <Authenticator>
              <Albums />
            </Authenticator>
          </Route>
          <Route path="/members">
            <Members />
          </Route>
          <Route path="/">
            <Frontpage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}