import React from 'react';
import styled from 'styled-components';
import { BrowserView, MobileView } from 'react-device-detect';

import Member from '../components/Member';

import { LinkedIn, RS, Github } from '../util/icons';

import image_fabomi from '../assets/familien.jpg';
import member_henrik from '../assets/henrik.jpg';
import member_helena from '../assets/helena.jpg';
import member_thomas from '../assets/thomas.jpg';
import member_mathias from '../assets/mathias.jpg';

const BrowserWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  ::before {
    content: "";
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-image: url(${image_fabomi});
    background-size: cover;
    background-position: center;
    opacity: .2;
  }
`;

const HeaderImage = styled.img`
  width: 100vw;
`;

const MembersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  width: calc(100vw - 48px);
  margin-left: 24px;
  padding-bottom: 40px;
`;

const members = [
  {
    name: 'Henrik',
    image: member_henrik,
    SoMe: [
      {
        href: "https://www.linkedin.com/in/henrik-boel-micheelsen-a806767/",
        icon: <LinkedIn />
      },
      {
        href: "https://secure.runescape.com/m=hiscore/compare?user1=Hebomi",
        icon: <RS />
      },
    ]
  },
  {
    name: 'Helena',
    image: member_helena,
    SoMe: [
      {
        href: "https://www.linkedin.com/in/helena-boel-micheelsen-24664b69/",
        icon: <LinkedIn />
      },
    ]
  },
  {
    name: 'Thomas',
    image: member_thomas,
    SoMe: [
      {
        href: "https://www.linkedin.com/in/thomas-boel-micheelsen-72502514b/",
        icon: <LinkedIn />
      },
      {
        href: "https://github.com/thomasboel",
        icon: <Github />
      },
    ]
  },
  {
    name: 'Mathias',
    image: member_mathias,
    SoMe: [
      {
        href: "https://secure.runescape.com/m=hiscore_oldschool/hiscorepersonal.ws?user1=yikers_yikes",
        icon: <RS />
      },
    ]
  }
]

const Members = () => {
  return (
    <React.Fragment>
      <BrowserView>
        <BrowserWrapper>
          {members.map((member, index) => (
            <Member key={index} member={member} />
          ))}
        </BrowserWrapper>
      </BrowserView>
      <MobileView>
        <HeaderImage src={image_fabomi}/>
        <MembersContainer>
          {members.map((member, index) => (
            <Member key={index} member={member} />
          ))}
        </MembersContainer>
      </MobileView>
    </React.Fragment>
  );
}

export default Members;