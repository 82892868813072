import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { HomeIcon } from '../util/icons';
import { colors } from '../util/theme';

const Wrapper = styled.div`
  transform: translateX(-50%) translateY(-50%);
  width: ${isMobile ? '170px' : '300px'};
  height: ${isMobile ? '170px' : '300px'};
  
  &:before {
    content: '';
    position: relative;
    display: block;
    width: ${isMobile ? '254px' : '600px'};
    height: ${isMobile ? '254px' : '600px'};
    box-sizing: border-box;
    border-radius: 300px;
    margin: ${isMobile ? '42px' : 0};
    background-color: ${colors.green};
    animation: pulse-ring 2.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
  
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: ${isMobile ? '25%' : '50%'};
    left: 50%;
    background-color: white;
    border-radius: 300px;
    /* box-shadow: 0 0 8px rgba(0,0,0,.3); */
    /* animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite; */
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(.33);
    }
    80%, 100% {
      opacity: 0;
    }
  }

  @keyframes pulse-dot {
    0% {
      transform: scale(.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(.8);
    }
  }
`;

const HomeIconWrapper = styled.div`
  position: absolute;
  top: ${isMobile ? 'calc(50% + 10px)' : 'calc(50% + 70px)'};
  left:  ${isMobile ? 'calc(50% + 48px)' : 'calc(50% + 60px)'};
  z-index: 2;
`;

const HomePulsator = () => {
  return (
    <Wrapper>
      <HomeIconWrapper>
        <HomeIcon big={!isMobile} mobile={isMobile} />
      </HomeIconWrapper>
    </Wrapper>
  );
}

export default HomePulsator;