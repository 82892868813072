import React from 'react';
import styled from 'styled-components';

import { isMobile } from 'react-device-detect';

import Label from './Label';

import { colors } from '../util/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  margin-top: ${isMobile ? 24 : 0}px;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${isMobile ? '150px' : '300px'};
  height: ${isMobile ? '150px' : '300px'};
  border-radius: ${isMobile ? '75px' : '150px'};
  background-color: ${colors.lightGrey};
`;

const Image = styled.div`
  width: ${isMobile ? '124px' : '250px'};
  height: ${isMobile ? '124px' : '250px'};
  border-radius: ${isMobile ? '62px' : '125px'};
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const SoMe = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

const SoMeIcon = styled.a`
  width: 30px;
  height: 30px;
  margin: 0 12px 0 12px;
`;

const Member = ({ member }) => {
  return (
    <Wrapper>
      <Circle>
        <Image src={member.image} />
      </Circle>
      <Label label={member.name} fontSize={isMobile ? 18 : 36} style={{ marginTop: isMobile ? 16 : 24 }} />
      <SoMe>
        {member.SoMe && member.SoMe.map((media, index) => (
          <SoMeIcon href={media.href} target="_blank">
            {media.icon}
          </SoMeIcon>
        ))}
      </SoMe>
    </Wrapper>
  );
}

export default Member;