import React, { useState } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import Label from './Label';

import { TrashCanIcon } from '../util/icons';

const Wrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 60px;

  &:hover {
    background-color: #DEDEDE;
  }
`;

const TrashWrapper = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  right: 10px;

  width: 20px;
  height: 24px;

  &:hover {
    cursor: pointer;
  }
`;

const ListItem = ({ item, deleteItem }) => {
  const [ hover, setHover ] = useState(false);

  return (
    <Wrapper onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Label label={item.name} fontSize={26} style={{ padding: '16px 20px', maxWidth: '80%', wordBreak: 'break-word' }} />
      {(hover || isMobile) && <TrashWrapper onClick={() => deleteItem(item)}>
        <TrashCanIcon />
      </TrashWrapper>}
    </Wrapper>
  );
}

export default ListItem;