import React from 'react';
import styled from 'styled-components';
import { colors } from '../util/theme';

const Text = styled.div`
  font-size: ${props => props.fontSize}px;
  font-weight: ${props => props.fontWeight};
  color: ${props => props.color};
`;

const Label = ({ label, fontSize=24, fontWeight=300, color=colors.black, style=null }) => {
  return (
    <Text
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      style={style}
    >
      {label}
    </Text>
  );
}

export default Label;