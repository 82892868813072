import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import Label from './Label';
import ListItem from './ListItem';

import { colors } from '../util/theme';

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: ${props => isMobile ? '100%' : `25vw`};
  border: 1px solid ${colors.white};
`;

const PersonCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: calc(100% - 40px);
  padding: 20px;
  background-color: ${colors.green};

  border-bottom: 2px solid ${colors.white};
`;

const AddItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 60px;
  
  border-bottom: 2px solid ${colors.white};
`;

const Inputfield = styled.input`
  margin-left: 20px;
  height: 40px;
  width: calc(100% - 68px);

  outline: none;
  border: none;
  padding: 0;
  text-indent: 20px;
  color: ${colors.black};
`;

const AddButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  background-color: ${colors.white};
  margin-right: 20px;
  margin-left: 4px;

  &:hover {
    cursor: pointer;
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const List = ({ member, columns, items, addItem, deleteItem }) => {
  const [ name, setName ] = useState('');

  const handleUserKeyPress = useCallback(event => {
    const { keyCode } = event;

    if (keyCode === 13 ) {
      if (name.length > 0) {
        addItem(name, member);
        setName('');
      }
    }
  }, [name, addItem, member]);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <ColumnWrapper columns={columns}>
      <PersonCell>
        <Label label={member} color={colors.white} fontSize={26} />
      </PersonCell>
      <AddItem>
        <Inputfield type={"text"} value={name} placeholder={"Nyt ønske"} onChange={(e) => setName(e.target.value)}></Inputfield>
        <AddButton onClick={() => { 
          if (name.length > 0) {
            addItem(name, member);
            setName('');
          }
        }}>
          <Label label={'+'} fontSize={26} />
        </AddButton>
      </AddItem>
      <ItemsContainer>
        {items.map((item, index) => (
          <ListItem key={index} item={item} deleteItem={(item) => deleteItem(item.id)} />
        ))}
      </ItemsContainer>
    </ColumnWrapper>
  );
}

export default List;