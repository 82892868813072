import React, { useState, useEffect } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import styled from 'styled-components';
import _ from 'lodash';

import Label from '../components/Label';
import List from '../components/List';

import { colors } from '../util/theme';

const Wrapper = styled.div`
  position: absolute;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;

  max-width: 70vw;
  min-height: 800px;

  background-color: ${colors.lightGrey};
`;

const ListWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;

  width: 100vw;

  background-color: ${colors.lightGrey};
`;

const api_url = 'https://api.boelmicheelsen.dk/wish-list';

const members = [ "Henrik", "Helena", "Mathias", "Thomas" ];

const WishListComponent = () => {
  const [ items, setData ] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(api_url + `?token=${localStorage.getItem('fabomi.authToken')}`).then(res => res.json());
      setData(data);
    } 
    fetchData();
    setInterval(() => fetchData(), 5000);
  }, []);

  const addItem = (name, member) => {
    fetch(api_url + `?token=${localStorage.getItem('fabomi.authToken')}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name,
        member
      })
    }).then(() => fetch(api_url + `?token=${localStorage.getItem('fabomi.authToken')}`).then(res => res.json()).then(resJson => setData(resJson)));
  }

  const deleteItem = (id) => {
    fetch(api_url + `/${id}` + `?token=${localStorage.getItem('fabomi.authToken')}`, {
      method: 'DELETE'
    }).then(() => fetch(api_url + `?token=${localStorage.getItem('fabomi.authToken')}`).then(res => res.json()).then(resJson => setData(resJson)));
  }

  return (
    <React.Fragment>
      <BrowserView>
        <Wrapper>
          <Label label={'🎄 JULE ØNSKE LISTE 🎁'} fontSize={36} style={{ paddingBottom: 40, letterSpacing: 10 }} />
          <ListWrapper columns={members.length}>
            {members.map((member, index) => (
              <List 
                key={index} 
                columns={members.length}
                member={member} 
                items={_.filter(items, ['member', member])} 
                addItem={(name, member) => addItem(name, member)}
                deleteItem={(id) => deleteItem(id)}
              />
            ))}
          </ListWrapper>
        </Wrapper>
      </BrowserView>
      <MobileView>
        <ListWrapperMobile>
          {members.map((member, index) => (
            <List 
              key={index} 
              columns={members.length}
              member={member} 
              items={_.filter(items, ['member', member])} 
              addItem={(name, member) => addItem(name, member)}
              deleteItem={(id) => deleteItem(id)}
            />
          ))}
        </ListWrapperMobile>
      </MobileView>
    </React.Fragment>
  );
}

export default WishListComponent;