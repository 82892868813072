import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Label from '../components/Label';

import { colors } from '../util/theme';

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`;

const AuthModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 150px);
  z-index: 1;

  width: 300px;
  height: 200px;

  border-radius: 16px;

  background-color: ${colors.lightGrey};
`;

const Inputfield = styled.input`
  width: 200px;
  height: 32px;

  outline: none;
  border: none;
  padding: 0;
  text-indent: 8px;
  color: ${colors.black};
  margin-top: 20px;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 150px;
  height: 32px;
  margin-top: auto;
  margin-bottom: 20px;
  background-color: ${colors.green};

  &:hover {
    cursor: pointer;
  }
`;

const api_url = 'https://api.boelmicheelsen.dk/auth';

const Authenticator = ({ children }) => {
  const [ authenticated, setAuthenticated ] = useState(false);
  const [ error, setError ] = useState('');
  const [ password, setPassword ] = useState('');

  useEffect(() => {
    tryAuthenticate(true);
    // eslint-disable-next-line
  }, [])

  const tryAuthenticate = (isOnApplicationLoad) => {
    const localStoragePassword = localStorage.getItem('fabomi.password');

    fetch(api_url + `?token=${isOnApplicationLoad ? localStoragePassword : password}`).then(async (res) => {
      if (res.ok) {
        const token = await res.text();
        setAuthenticated(true);
        !isOnApplicationLoad && localStorage.setItem('fabomi.password', password);
        localStorage.setItem('fabomi.authToken', token);
      } else {
        setError('Forkert kodeord');
      }
    });
  }

  return (
    <Wrapper>
      {authenticated ? (
        children
      ) : (
        <AuthModal>
          <Label label={'Kodeord'} style={{ marginTop: 16 }} />
          <Inputfield type={'password'} value={password} onChange={(e) => setPassword(e.target.value)}></Inputfield>
          {error.length > 0 && <Label label={error} color={'#F00'} />}
          <Button onClick={() => tryAuthenticate(false)}>
            <Label label={'Godkend'} fontSize={16} color={colors.white} />
          </Button>
        </AuthModal>
      )}
    </Wrapper>
  );
}

export default Authenticator;