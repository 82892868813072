import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { isMobile } from 'react-device-detect';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

import Label from '../components/Label';

const api_url = 'https://api.boelmicheelsen.dk/albums' + `?token=${localStorage.getItem('fabomi.authToken')}`;

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`;

const GalleryWrapper = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${isMobile ? '40px' : '200px'};
  margin-bottom: 40px;
`;

const Albums = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currentAlbum, setCurrentAlbum] = useState(null);

  const [albums, setAlbums] = useState({});

  useEffect(() => {
    fetch(api_url)
    .then(res => res.json())
    .then(data => {

      setAlbums(data.albums);
    });
  }, []);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
    
    const albumKey = getAlbumFromPhoto(photo.src);

    const album = albums[albumKey].map(img => ({
      src: `https://fabomi-albums.s3.eu-central-1.amazonaws.com/${albumKey}/${img}`,
      width: 4,
      height: 3
    }));

    setCurrentAlbum(album);
  }, [albums]);

  const getAlbumFromPhoto = (url) => {
    return url.split('/')[3];
  }

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <Wrapper>
      <GalleryWrapper>

        {Object.keys(albums).map((albumKey) => {
          const galleryAlbum = [];
          albums[albumKey].forEach((photo) => {
            galleryAlbum.push({
              src: `https://fabomi-albums.s3.eu-central-1.amazonaws.com/${albumKey}/${photo}`,
              width: 4,
              height: 3
            });
          });
          return (
            <>
              <Label label={albumKey.replace('_', ' ')} fontSize={36} style={{ marginTop: 24, paddingBottom: 40, letterSpacing: 10, textAlign: 'center' }} />
              <Gallery photos={galleryAlbum} onClick={openLightbox} style={{ width: '100%' }} />
            </>
          );
        })}
        {/*
        <Label label={'Sommer 2009'} fontSize={36} style={{ paddingBottom: 40, letterSpacing: 10, textAlign: 'center' }} />
        <Gallery photos={albums.album_sommer2009} onClick={openLightbox} style={{ width: '100%' }} />
        <Label label={'Thomas Fødselsdag'} fontSize={36} style={{ paddingBottom: 40, letterSpacing: 10, marginTop: isMobile ? 40 : 120, textAlign: 'center' }} />
        <Gallery photos={albums.album_thomasBirthday} onClick={openLightbox} style={{ width: '100%' }} />
        <Label label={'Thomas Bowling'} fontSize={36} style={{ paddingBottom: 40, letterSpacing: 10, marginTop: isMobile ? 40 : 120, textAlign: 'center' }} />
        <Gallery photos={albums.album_thomasBowling} onClick={openLightbox} style={{ width: '100%' }} />
        */}
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={currentAlbum.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </GalleryWrapper>
    </Wrapper>
  );
}

export default Albums;