import React from 'react';
import styled from 'styled-components';

import HomePulsator from '../components/HomePulsator';
import Label from '../components/Label';

import { MapPin } from '../util/icons';

import image_vestermarks from '../assets/vestermarksvej.jpg';
import image_slusevej from '../assets/slusevej_alt.jpg';
import maps_vestermarks from '../assets/vestermarksvej.png';
import maps_slusevej from '../assets/slusevej.png';
import mobile_vestermarksvej from '../assets/mobile_haven.png';
import mobile_slusevej from '../assets/mobile_slusevej.png';

import video_vestermarks from '../assets/fabomi-header-video.mp4';

import { BrowserView, MobileView, isMobile } from 'react-device-detect';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => props.center ? 'center' : 'flex-start'};

  width: 100%;
  height: 100vh;
`;

const PulsatorContainer = styled.div`
  margin-top: ${isMobile ? '100px' : '200px'};
`;

const PinInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  max-width: 825px;
  margin-top: 150px;
`;

const WholePageImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${props => props.image};
  background-size: cover;
`;

const MapAndInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 70vw;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-left: ${props => props.right ? '0' : 'auto'};
`;

const MapImage = styled.img`
  width: ${isMobile ? 'calc(100vw - 80px)' : 'auto'};
  height: ${isMobile ? 'auto' : '70vh'};
  margin-left: ${props => !isMobile && (props.right ? 'auto' : '0')};
`;

const SectionImage = styled.img`
  position: relative;
  width: 100%;
`;

const texts = {
  intro: `Hejsa, du har fundet frem til familien Boel Micheelsen's hjemme-side. \nDen er organisatorisk placeret et lille stykke før enden på internettet, så pas på med at gå videre.\n\nHer vil være lidt information om de enkelte medlemmer samt billeder og "lækre" links.`,
  vestermarksvej: 'Vestermarksvej 32',
  vestermarksvej_info: `På en lille sidevej mellem Vejlands Allé og Englandsvej ligger Vestermarksvej 32. Mor og far flyttede dertil kort før Thomas' fødsel, og har boet der lige siden. \nLillebror bor stadig hjemme i det fede annex, som storebror også boede i inden han fløj fra reden. Grunden har både for-og baghave hvor blandt andet megen boldspil har fundet sted.`,
  slusevej: 'Slusevej 34U',
  slusevej_info: `I et lille 2 værelses rækkehus, bor storebror sammen med sin kæreste. De flyttede ind i 2020 under corona pandemien, men har heldigvis ikke lidt nogle symptomer. \n\nRækkehuset er en del af Amagerkollegiet, et fint katte-kollegie med egen køkken og bad, og massere af grønt areal. Ligesom huset på Vestermarksvej, så bor storebror også på Amager, og har faktisk aldrig boet på andre danske øer!`
}

const Frontpage = () => {
  return (
    <React.Fragment>
      {/* ==================== BROWSER ==================== */}
      <BrowserView>
        <Wrapper>
          <Section style={{ height: 'auto', paddingBottom: 150 }}>
            <video src={video_vestermarks} autoPlay loop muted style={{ width: '60vw', marginTop: 150, borderRadius: 40 }} />
            <PinInformationContainer>
              <MapPin />
              <Label label={texts.intro} fontSize={20} style={{ width: 650, marginLeft: 120, whiteSpace: 'pre-wrap' }} />
            </PinInformationContainer>
          </Section>
          <Section>
            <WholePageImage image={`url(${image_vestermarks})`} />
          </Section>
          <Section center>
            <MapAndInfoContainer>
              <MapImage src={maps_vestermarks} />
              <InfoContainer>
                <Label label={texts.vestermarksvej} fontSize={36} fontWeight={800} style={{ width: '33vw', textAlign: 'center' }} />
                <Label label={texts.vestermarksvej_info} fontSize={20} style={{ width: '33vw', textAlign: 'center', marginTop: 40 }} />
              </InfoContainer>
            </MapAndInfoContainer>
          </Section>
          <Section>
            <WholePageImage image={`url(${image_slusevej})`} />
          </Section>
          <Section center>
            <MapAndInfoContainer>
              <InfoContainer right>
                <Label label={texts.slusevej} fontSize={36} fontWeight={800} style={{ width: '33vw', textAlign: 'center' }} />
                <Label label={texts.slusevej_info} fontSize={20} style={{ width: '33vw', textAlign: 'center', marginTop: 40 }} />
              </InfoContainer>
              <MapImage right src={maps_slusevej} />
            </MapAndInfoContainer>
          </Section>
        </Wrapper>
      </BrowserView>
      {/* ==================== MOBILE ==================== */}
      <MobileView>
        <Wrapper>
          <PulsatorContainer>
            <HomePulsator />
          </PulsatorContainer>
          <MapPin style={{ marginTop: 40 }} />
          <Label label={texts.intro} fontSize={20} style={{ width: '90vw', marginTop: 32, textAlign: 'center', whiteSpace: 'pre-wrap', paddingBottom: 80 }} />
          <div style={{ position: 'relative', width: '100%' }}>
            <SectionImage src={mobile_vestermarksvej} />
            <MapImage src={maps_vestermarks} style={{ position: 'absolute', bottom: '-25%', left: 40, zIndex: 3 }} />
          </div>
          <Label label={texts.vestermarksvej} fontSize={36} fontWeight={800} style={{ textAlign: 'center', marginTop: '50%' }} />
          <Label label={texts.vestermarksvej_info} fontSize={20} style={{ paddingBottom: 40, width: 'calc(100% - 48px)', textAlign: 'center', marginTop: 16 }} />
          <div style={{ position: 'relative', width: '100%' }}>
            <SectionImage src={mobile_slusevej} />
            <MapImage src={maps_slusevej} style={{ position: 'absolute', bottom: '-25%', left: 40, zIndex: 3 }} />
          </div>
          <Label label={texts.slusevej} fontSize={36} fontWeight={800} style={{ textAlign: 'center', marginTop: '50%' }} />
          <Label label={texts.slusevej_info} fontSize={20}  style={{ paddingBottom: 40, width: 'calc(100% - 48px)', textAlign: 'center', marginTop: 16 }} />
        </Wrapper>
      </MobileView>
    </React.Fragment>
  );
}

export default Frontpage;